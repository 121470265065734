


































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import LinkedCustomInvestorGoalBodyCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-goals/linked-custom-investor-goal-body-card-view-model';

// Domain
import {
  LinkedCustomInvestorGoalCardDataDto,
} from '@/modules/flagship/investor-goal/linked-investor-goal/domain/dto/linked-custom-investor-goal-card-data-dto';

@Component({
  name: 'LinkedCustomInvestorGoalBodyCard',
  components: {},
})
export default class LinkedCustomInvestorGoalBodyCard extends Vue {
  @PropSync('cardData', { type: Object, required: true })
  card_data!: LinkedCustomInvestorGoalCardDataDto;

  view_model = Vue.observable(new LinkedCustomInvestorGoalBodyCardViewModel());
}
